export const environment = {
    production: false,
    API_BASE_URL:"https://staging-node-cardiacmd.enterpriseapplicationdevelopers.com:3028/",
    API_PATH:"api/v1/",
    ENCRYPT:"TSOLRgdkyuyFYrKZvaNiXdwHwq7Vsw==",
    IV:"5183666c72eec9e4",
    API_DOCTOR_URL:"doctor/api/v1/",
    API_ADMIN_URL:"admin/api/v1/",
    RECAPTCHA_KEY:"6Lexx9sbAAAAAJdkepCvtsAuKdIkfOYLl1mJbypT",
    SOCKETURL : "https://staging-node-cardiacmd.enterpriseapplicationdevelopers.com:3028"
};