import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { FuseUtilsService } from '@fuse/services/utils/utils.service';
import { SharedService } from 'app/shared/shared.service';
import { AdminService } from 'app/modules/admin/admin.service';
import { io } from 'socket.io-client';
import { environment } from 'environments/environment';

@Component({
    selector: 'fuse-vertical-navigation-basic-item',
    templateUrl: './basic.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuseVerticalNavigationBasicItemComponent
    implements OnInit, OnDestroy
{
    private socket;
    @Input() item: FuseNavigationItem;
    @Input() name: string;
    @Input() hideAdmin: boolean;
    @Input() hideDoctor: boolean;

    addClass: string = '';

    isActiveMatchOptions: IsActiveMatchOptions;
    private _fuseVerticalNavigationComponent: FuseVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    orderedDeviceStatus = new BehaviorSubject<any[]>([]);
    response = this.orderedDeviceStatus.asObservable();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseUtilsService: FuseUtilsService,
        private sharedService: SharedService,
        private adminService: AdminService
    ) {
        this.sharedService.access.subscribe((value) => {
            //console.log(value, 'dfsdf');
            if (value == 2) {
            }
        });
        // Set the equivalent of {exact: false} as default for active match options.
        // We are not assigning the item.isActiveMatchOptions directly to the
        // [routerLinkActiveOptions] because if it's "undefined" initially, the router
        // will throw an error and stop working.
        this.isActiveMatchOptions = this._fuseUtilsService.subsetMatchOptions;
        this.socket = io(environment.SOCKETURL);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Set the "isActiveMatchOptions" either from item's
        // "isActiveMatchOptions" or the equivalent form of
        // item's "exactMatch" option
        //console.log(this.item);
        this.sharedService.accessSpecifier.subscribe((value: any) => {
            //console.log('Shared Serive ', value);
        });
        //console.log(this.item);
        if (this.hideAdmin && this.item.id == 'Admin') {
            this.addClass = 'hideAdmin';
        } else {
            this.addClass = '';
        }
        if (this.hideDoctor && this.item.id == 'Doctor') {
            this.addClass = 'hideDoctor';
        } else {
            this.addClass = '';
        }
        //To show the dot notification when there are newly ordered devices in the Kitting Menu
        if (this.item.id == 'Admin' && this.item.title == 'Kitting') {
            this.getOrderedDeviceStatus();
        }
        this.isActiveMatchOptions =
            this.item.isActiveMatchOptions ?? this.item.exactMatch
                ? this._fuseUtilsService.exactMatchOptions
                : this._fuseUtilsService.subsetMatchOptions;

        // Get the parent navigation component
        this._fuseVerticalNavigationComponent =
            this._fuseNavigationService.getComponent(this.name);

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Subscribe to onRefreshed on the navigation component
        this._fuseVerticalNavigationComponent.onRefreshed
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
            this.socket.on('devicenotification', (message) => {
                if (this.item.id == 'Admin' && this.item.title == 'Kitting') {
                    this.getOrderedDeviceStatus();
                    // let b_data : any = true
                    // this.orderedDeviceStatus.next(b_data)
                }
                
          });
    }
    //To get the data whether newly ordered devices exists
    getOrderedDeviceStatus() {
        this.adminService.getOrderedDeviceStatus().subscribe((data: any) => {
            console.log("===========")
            console.log(data.data);
            this.orderedDeviceStatus.next(data.data);
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
