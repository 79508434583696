import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EncryptDecryptService } from 'app/core/encrypt-decrypt.service';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    roleEncryption = localStorage.getItem('accessModifier');
    constructor(
        private encrypt: EncryptDecryptService,
        private _httpClient: HttpClient
    ) {}
    getPatientList(request) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        if (role == '1' || role == '6') {
            api_url =
                environment.API_BASE_URL +
                '' +
                environment.API_ADMIN_URL +
                'patient/get';
        }
        return this._httpClient
            .post<any>(api_url, request, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }

    getCountList(request) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        if (role == '1' || role == '6') {
            api_url =
                environment.API_BASE_URL +
                '' +
                environment.API_ADMIN_URL +
                'patient/getcount';
        }
        return this._httpClient
            .post<any>(api_url, request, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }

    getUserPatientList(request) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        if (role == '1' || role == '6') {
            api_url =
                environment.API_BASE_URL +
                '' +
                environment.API_ADMIN_URL +
                'adminAccount/getuserpatientlist';
        }
        return this._httpClient
            .post<any>(api_url, request, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }
    getOrganizationList(request) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        if (role == '1' || role == '6') {
            api_url =
                environment.API_BASE_URL +
                '' +
                environment.API_ADMIN_URL +
                'adminAccount/get-list';
        }

        return this._httpClient
            .post<any>(api_url, request, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }
    changeStatus(request) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        if (role == '1' || role == '6') {
            api_url =
                environment.API_BASE_URL +
                '' +
                environment.API_ADMIN_URL +
                'patient/change-status';
        }

        return this._httpClient
            .post<any>(api_url, request, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }
    changeAccountStatus(request) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        if (role == '1' || role == '6') {
            api_url =
                environment.API_BASE_URL +
                '' +
                environment.API_ADMIN_URL +
                'adminAccount/change-status';
        }
        return this._httpClient
            .post<any>(api_url, request, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }
    fileExport(encryptedRequest) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        console.log(role);
        console.log('testing');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'patient/fileExport';
        /*return this._httpClient.post<any>(api_url,encryptedRequest,{
        headers:{
          'Accept': 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet',
          'authorization':'Bearer '+accessToken
          },
      }*/
        let headers = new HttpHeaders();
        headers = headers.set(
            'Accept',
            'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet'
        );
        headers = headers.set('authorization', 'Bearer ' + accessToken);
        return this._httpClient
            .post(api_url, encryptedRequest, {
                headers: headers,
                responseType: 'blob' as 'json',
            })
            .pipe(
                switchMap((data: any) => {
                    /*console.log(data);
      console.log('000000');
        const decryptData = this.encrypt.decryptData(data.data);
        console.log(decryptData);
        console.log('aaaaaaa');*/
                    return of(data);
                })
            );
    }
    recordsfileExport(encryptedRequest) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        console.log(role);
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'patient/recordsfileExport';
        let headers = new HttpHeaders();
        headers = headers.set(
            'Accept',
            'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet'
        );
        headers = headers.set('authorization', 'Bearer ' + accessToken);
        return this._httpClient.post(api_url, encryptedRequest, {
            headers: headers,
            responseType: 'blob' as 'json',
        });
    }
    DoctorsFileExport(encryptedRequest) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        console.log(role);
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'adminAccount/doctorsListfileExport';
        let headers = new HttpHeaders();
        headers = headers.set(
            'Accept',
            'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet'
        );
        headers = headers.set('authorization', 'Bearer ' + accessToken);
        return this._httpClient
            .post(api_url, encryptedRequest, {
                headers: headers,
                responseType: 'blob' as 'json',
            })
            .pipe(
                switchMap((data: any) => {
                    return of(data);
                })
            );
    }
    getPatientDetail(patientUuid) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'patient/getDetail';
        return this._httpClient
            .post<any>(api_url, patientUuid, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    return of(decryptData);
                })
            );
    }
    getStates() {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        //if(role == '2'){
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_DOCTOR_URL +
            'states';
        //}

        return this._httpClient
            .get<any>(api_url, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    return of(decryptData);
                })
            );
    }
    updatePatient(patientDetails) {
        console.log(patientDetails);
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_DOCTOR_URL +
            'patient/update';

        return this._httpClient
            .post<any>(api_url, patientDetails, {
                headers: {
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }
    getUserDetails(request) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        //if(role == '2'){
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'adminAccount/getUserDetils';

        return this._httpClient
            .post<any>(api_url, request, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    return of(decryptData);
                })
            );
    }
    updateOrganizationDetails(userDetails) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'updateOrganizationDetails';

        return this._httpClient
            .post<any>(api_url, userDetails, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }
    getStaffList(request) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        //if(role == '2'){
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'adminAccount/getStaffList';

        return this._httpClient
            .post<any>(api_url, request, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    return of(decryptData);
                })
            );
    }
    //Get All Staff List
    getAllStaffList(request = {}) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        //if(role == '2'){
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_DOCTOR_URL +
            'staff/getAllStaffList';

        return this._httpClient
            .post<any>(api_url, request, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    return of(decryptData);
                })
            );
    }
    //Get All Staff List
    getAllOrganizationList(request = {}) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        //if(role == '2'){
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_DOCTOR_URL +
            'staff/getAllOrganizationList';

        return this._httpClient
            .post<any>(api_url, request, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    return of(decryptData);
                })
            );
    }
    addAdminDetails(adminDetails) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_DOCTOR_URL +
            'staff/addAdmin';

        return this._httpClient
            .post<any>(api_url, adminDetails, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }
    getadminList(getAdminParams) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_DOCTOR_URL +
            'staff/getAdminList';

        return this._httpClient
            .post<any>(api_url, getAdminParams, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }
    doctorDetailsExport(encryptedRequest) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'adminAccount/generatePdfDoctorDetails';
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        headers = headers.set('authorization', 'Bearer ' + accessToken);
        return this._httpClient
            .post(api_url, encryptedRequest, {
                headers: headers,
                responseType: 'blob' as 'json',
            })
            .pipe(
                switchMap((data: any) => {
                    return of(data);
                })
            );
    }
    getRecordsList(request) {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'getRequestDevices';

        return this._httpClient
            .post<any>(api_url, request, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    return of(decryptData);
                })
            );
    }
    UpdateOrderDevicesStatus(requestParam) {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'changeStatus';

        return this._httpClient
            .post<any>(api_url, requestParam, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    return of(decryptData);
                })
            );
    }

    getOrderedDeviceStatus() {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'getDeviceRequestedNotification';

        return this._httpClient
            .get<any>(api_url, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    //console.log(decryptData);
                    return of(decryptData);
                })
            );
    }

    insertOrganizationDetails(payload) {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            '/insertOrganizationDetails';

        return this._httpClient
            .post<any>(api_url, payload, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }

    checkOrganizationDetails(payload) {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            '/checkOrganizationDetails';

        return this._httpClient
            .post<any>(api_url, payload, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }

    getDepartmentList() {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL + 
            'department/get';

        return this._httpClient
            .get<any>(api_url, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    //console.log(decryptData);
                    return of(decryptData);
                })
            );
    }

    insertDepartmentDetails(payload) {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'department/insert';

        return this._httpClient
            .post<any>(api_url, payload, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }

    updateDepartmentDetails(payload) {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'department/changeData';

        return this._httpClient
            .patch<any>(api_url, payload, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }

    checkDepartmentDetails(payload) {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'department/checkDepartmentDetails';

        return this._httpClient
            .post<any>(api_url, payload, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }

    getPatientGroupList() {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL + 
            'patientgroup/get';

        return this._httpClient
            .get<any>(api_url, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    //console.log(decryptData);
                    return of(decryptData);
                })
            );
    }

    getActivePatientGroup() {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL + 
            'patientgroup/getActiveGroup';

        return this._httpClient
            .get<any>(api_url, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    //console.log(decryptData);
                    return of(decryptData);
                })
            );
    }

    insertPatientGroupDetails(payload) {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'patientgroup/insert';

        return this._httpClient
            .post<any>(api_url, payload, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }

    updatePatientGroupDetails(payload) {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'patientgroup/changeData';

        return this._httpClient
            .patch<any>(api_url, payload, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }

    checkPatientGroupDetails(payload) {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'patientgroup/checkPatientGroupDetails';

        return this._httpClient
            .post<any>(api_url, payload, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    console.log(decryptData);
                    return of(decryptData);
                })
            );
    }

    getOrganizationManagedDetails(request) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'getOrganizationManagedDetails';

        return this._httpClient
            .post<any>(api_url, request, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    return of(decryptData);
                })
            );
    }

    getUserDetailsForOrganization(request) {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'getUserDetailsForOrganization';

        return this._httpClient
            .post<any>(api_url, request, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    return of(decryptData);
                })
            );
    }

    getUserList(payload) {
        const accessToken = localStorage.getItem('accessToken');
        let roleEncryption = localStorage.getItem('accessModifier');
        const role = this.encrypt.decryptData(roleEncryption).toString();
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_ADMIN_URL +
            'getUserList';
        return this._httpClient
            .post<any>(api_url, payload, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    return of(decryptData);
                })
            );
    }
    changeStaffOrganization(requestParam) {
        const accessToken = localStorage.getItem('accessToken');
        let api_url = '';
        api_url =
            environment.API_BASE_URL +
            '' +
            environment.API_DOCTOR_URL +
            'staff/changeStaffOrganization';

        return this._httpClient
            .post<any>(api_url, requestParam, {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + accessToken,
                },
            })
            .pipe(
                switchMap((data: any) => {
                    const decryptData = this.encrypt.decryptData(data.data);
                    return of(decryptData);
                })
            );
    }
}
